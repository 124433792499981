import React, {useState, useEffect, useContext} from 'react';
import { AuthContext } from '../context/AuthContext';
import Service from '../components/Service';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import Media from '../components/Media';

function showStars(n){
  let r = '';
  for (var i = 0; i < parseInt(n); i ++){
    r += '★';
  }
  return r;
}

function cutText(text, length = 50){
  let res;
  if(text.length > length){
    res = text.substring(0,length);
    let li = res.lastIndexOf(" ");
    return res.substring(0, li) + '...';
  }else{
    return text;
  }
}

const modules = {
  toolbar: [
    [{ 'header': [2, 3, false] }],
    ['bold', 'italic', 'underline','strike'],
    // ['link', 'image'],
    ['link'],
    ['clean'],
    [{'list': 'ordered'}, {'list': 'bullet'}]
  ],
}

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike',
  'link', 'list' //'image',
  
]



export default function Offers(props) {

  const [offers, setOffers] = useState([]);
  const [add, setAdd] = useState();

  const [newOffer, setNewOffer] = useState({});

  const [offer, setOffer] = useState({});

  

  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [regionsFilter, setRegionsFilter] = useState(null);
  const [hotelsFilter, setHotelsFilter] = useState(null);
  const [image, setImage] = useState();
  const [media, setMedia] = useState(false);
  const [types, setTypes] = useState([]);
  const [price, setPrice] = useState();
  const [shortDescription, setShortDescription] = useState();
  const [selectedRegion, setSelectedRegion] = useState({label: null, value: null});
  const [selectedHotel, setSelectedHotel] = useState({label: null, value: null});
  const [hotels, setHotels] = useState();
  
  const [selectedType, setSelectedType] = useState({label: null, value: null});
  const [air, setAir] = useState();
  const [transfer, setTransfer] = useState();

  const [selectedOffer, setSelectedOffer] = useState();

//   const getHotels=()=>{
//     Service.get('/hotels/get', {}, (res)=>{
//         let t = [];
//         res.accommodation.map(item=> {
//             t.push({label: item.name, value: item.id}) 
//         });
//         setHotels(t);
//     })
//   }

  const getHotels = (inputValue, cb) => {
    Service.get('/hotels/get', {filter: hotelsFilter}, (res)=>{
      let options = [];
      res.accommodation.map(item => {
        options.push({label: item.name, value: item.id});
      })
      cb(options);
    })

  };

//   const {loggedIn, setLoggedIn} = useContext(AuthContext);

  const getOffers=()=>{
    Service.get('/offers/get', {}, (res)=>{
      setOffers(res.offers);
      let t = [];
      for(var p in res.types){
        t.push({label: res.types[p], value: p}) 
      }

      setTypes(t);
    })
  }


  const getRegions = (inputValue, cb) => {
    Service.get('/offers/getRegions', {filter: regionsFilter}, (res)=>{
      let options = [];
      res.regions.map(item => {
        options.push({label: item.name, value: item.id});
      })
      cb(options);
    })

  };

  const handleInputRegions = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    setRegionsFilter(inputValue);
    return inputValue;
  };

  const handleInputHotels = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    setHotelsFilter(inputValue);
    return inputValue;
  };

  useEffect(() => {
    if(props.match.params.id){

    }else{
      getOffers();
    }
    
    }, []);
  
  const addOffer = () => {

    if(!name || !price){
      alert('Greska! Niste uneli sve podatke.', name, price);
      return;
    }

    let params = {name: name,
      description: description,
    //   type: selectedType ? selectedType.value : 1,
      image: image ? image.name : null,
      price: price,
      shortDescription: shortDescription,
      regionId: selectedRegion.value,
      airTickets: air,
      transfer: transfer,
      hotel: selectedHotel.value
    }
    if(selectedOffer){
      params.id = selectedOffer;
      Service.get('/offers/update', params, (res)=>{
        if(res.success){
          resetInputs();
          alert('Izmene su uspesno sacuvane.');
          getOffers();
        }
      })
    }else{
      Service.get('/offers/addOffer', params, (res)=>{
        if(res.success){
          resetInputs();
          alert('Ponuda je uspesno dodata');
          getOffers();
        }
      })
    }
  }

  const resetInputs = () => {
      setDescription(null);
      setShortDescription(null);
      setPrice(null);
      setName(null);
      setImage(null);
        setOffer({});
        setAdd(false);
        setAir(null);
        setTransfer(null);
        setSelectedOffer(null);
        setSelectedHotel({label: null, value: null});
        setSelectedRegion({label: null, value: null});
  }

  useEffect(()=>{
    if(selectedOffer){
      getOffer();
    }
    
  }, [selectedOffer])

  const editOffer=(offerId)=>{
    setSelectedOffer(offerId);
  }

  const getOffer=()=>{
    Service.get('/offers/getOffer', {id: selectedOffer}, (res)=>{
      setAdd(true);
    //   setOffer( prevState => ({...prevState, 
    //     name: res.offer.name,
    //     description: res.offer.description,
    //     shortDescription: res.offer.short_description,
    //     price: res.offer.price,
    //     image: {url: res.offer.image, name: res.offer.image_name},
    //     region: {label: res.offer.region_name, value: res.offer.region}
    //     }));
        setDescription(res.offer.description);
        setShortDescription(res.offer.short_description);
        setPrice(res.offer.price);
        setName(res.offer.name);
        setImage({url: res.offer.image, name: res.offer.image_name});
        setSelectedRegion({label: res.offer.region_name, value: res.offer.region})
        setAir(res.offer.air_tickets);
        setTransfer(res.offer.transfer);
        setSelectedOffer(res.offer.id);
    //   setSelectedType({label: res.offer.type_text, value: res.offer.type});
    })
  }

  const deleteOffer=(offerId, offerName)=>{
    if(window.confirm("Da li ste sigurni da želite da obrišete ponudu " + offerName)){
      Service.get('/offers/delete', {id: offerId}, (res)=>{
        if(res.success){
          getOffers();
        }
      })
    }
  }

  const changeStatus=(id)=>{
    Service.get('/offers/changeStatus', {id}, (res)=>{
      if(res.success){
        getOffers();
      }
    })
  }


  
  
  return (
    <div>
      {/* <button onClick={()=>{setNewHotel( prevState => ({...prevState, name: 'vojno', test: 'test'})); console.log(newHotel)}}>test</button> */}
      {/* <button onClick={()=>{setNewHotel( prevState => ({...prevState, desc: 'kiki'})); console.log(newHotel)}}>test</button> */}
      <h1>Ponude {props.match.params.id}</h1>
      {add ? (
      <section>
        {selectedOffer ?
          <h2 className="align-center">Izmena ponude</h2>
          :
          <h2 className="align-center">Nova ponuda</h2>
          
        }
        <div className="flex">
          <div className="half">
            {/* <label>Ime</label> */}
            <input placeholder="Ime" onChange={(event)=>setName(event.target.value)} value={name} />
            <div className="flex">
            <div className="half">
              
              {/* <label>Cena</label> */}
              <input placeholder="Cena" type="number" onChange={(event)=>setPrice(event.target.value)} value={price} />
            </div>
          </div>
        
        {/* <label>Tip smeštaja</label> */}
        {/* {types &&
          <Select
          value={selectedType.label ? selectedType : {label: 'Odaberi tip...', value: 0}}
            placeholder="Tip ponude..."
            isSearchable={false}
            name="type"
            options={types}
            className="react-selector"
            onChange={(item) => setSelectedType(item)}
          />
        } */}

        <div>
            <input type="checkbox" checked={air} onChange={(event)=>{setAir(event.target.checked)}} />
            Avio karte
        </div>
        <div>
            <input type="checkbox" checked={transfer} onChange={(event)=>{setTransfer(event.target.checked)}} />
            Transfer
        </div>

        {/* <label>Država</label> */}

          <AsyncSelect
            value={selectedRegion.label ? selectedRegion : {label: 'Odaberi region...', value: 0}}
            cacheOptions
            loadOptions={getRegions}
            defaultOptions
            onInputChange={handleInputRegions}
            className="react-selector"
            placeholder="Odaberi region..."
            onChange={(item) => {setSelectedRegion(item)}}
          />

        <AsyncSelect
            value={selectedHotel.label ? selectedHotel : {label: 'Odaberi hotel...', value: 0}}
            cacheOptions
            loadOptions={getHotels}
            defaultOptions
            onInputChange={handleInputHotels}
            className="react-selector"
            placeholder="Odaberi hotel..."
            onChange={(item) => {setSelectedHotel(item)}}
          />
        
        <label>Slika</label>
        {image &&
          <img src={image.url} style={{height: '5em', display: 'block'}}/>
        }
        <button onClick={()=>setMedia(true)}>{image ? 'Promeni sliku' : 'Dodaj sliku'}</button>
        {/* <div>
            <label for="multi" class="btn">Odaberi sliku:</label>
            <input type='file' id='multi' name="userImage[]" onChange={uploadImage} multiple data-buttonText={"Your label here."} />
          </div> */}
        </div>
        <div className="half">
          {/* <label>Kratki opis</label> */}
              <input placeholder="Kratki opis"  onChange={(event)=>setShortDescription(event.target.value)} value={shortDescription} />
          {/* <label>opis</label> */}
          <ReactQuill value={description}
            placeholder="Opis ponude..."
            className="text-editor"
                      modules={modules}
                      formats={formats}
                    onChange={(value)=>setDescription(value)} 
          />
          <div className="align-right">
            <button onClick={addOffer}>Sačuvaj</button>
            <button className="button-cancel" onClick={()=>setAdd(false)}>Odustani</button>
          </div>
        </div>
        {media &&
          <Media className="media-module" onCancel={()=>{setMedia(false)}} onSelect={(image)=>{setImage(image); setMedia(false)}} />
        }
        </div>
      </section>
      ):(
        <button onClick={()=>setAdd(true)}>Dodaj ponudu</button>
      )
      }
      <div>
        <table className="cms-table">
          <thead>
              <tr>
                <th>id</th>
                <th>Slika</th>
                <th>Ime</th>
                <th>Opis</th>
                <th>Avio karte</th>
                <th>Transfer</th>
                <th>Region</th>
                <th>Hotel</th>
                <th>Cena</th>
                <th>Status</th>
                <th></th>
              </tr>
          </thead>
          <tbody>
          {offers.map(item => {
            return(
              <tr>
                <td>{item.id}</td>
                <td onClick={()=>editOffer(item.id)}><img style={{cursor: 'pointer'}} src={item.image} style={{width: '64px'}}/></td>
                <td><a onClick={()=>editOffer(item.id)} href="#">{item.name}</a></td>
                {/* <td>{cutText(item.description.replace(/(<([^>]+)>)/gi, " "), 50)}</td> */}
                <td>{item.short_description}</td>
                <td>{item.air_tickets ? '★' : null}</td>
                <td>{item.transfer ? '★' : null}</td>
                <td>{item.region_name}</td>
                <td>{item.hotel_name}</td>
                <td>{item.price}</td>
                <td><input type="checkbox" checked={item.status} onChange={()=>changeStatus(item.id)}/></td>
                <td><button className="button-red" onClick={()=>deleteOffer(item.id, item.name)}>Obriši</button></td>
              </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

