import React, { Component } from 'react';
import {LS} from '../components/LS';
class Home extends Component {

    constructor(props) {
    super(props);
  }

  componentDidMount(){
    //LS.clear();
    LS.set({test: {first: 1, second: null, third: 'awe'}, smor: ['vojno', 'laza'], pera: 'djoka'});

    console.log('storage', LS.data, LS.data.test.third);
  }

  render() {
    return (
      <div>
          <h1>Cloud 9 Travel</h1>
          <h2>Administracija</h2>
          <p>Milice, dobrodosla na svoj admin panel:) <br/>Ovde ces moci da dodajes sve sto ti je potrebno na sajtu.</p>
      </div>
    );
  }

}

export default Home;
