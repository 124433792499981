import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import {Auth} from './Auth';

export default function UserMenu (){
    const {setLoggedIn} = useContext(AuthContext)
    const logout=()=>{
        Auth.logout(()=>{
            setLoggedIn(false);
            window.location.href = '/login';
        });
    }

    const updateProvider=()=>{
        
    }

    const login=()=>{
        window.location.href = '/login';
    }

        return (
            <div id="user-menu">
                <span className="header-title">Cloud 9 Admin</span>

                {Auth.isAuthenticated &&
                    <div className="user-menu-right">
                        <p>{Auth.userName}</p>
                        <button onClick={logout}>Izloguj se</button>
                    </div>
                }

                {!Auth.isAuthenticated &&
                    <div className="user-menu-right">
                        <button className="user-menu-login" onClick={login}>Uloguj se</button>
                    </div>
                }
                
            </div>
        )
}

