import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

export default function RentACar(){

  const {loggedIn, setLoggedIn} = useContext(AuthContext);
  return (
    <div>
      <h1>Rent-A-Car</h1>
      {/* <div>Rent a car {loggedIn ? 'true' : 'false'}</div>
      <button onClick={()=>setLoggedIn(!loggedIn)} >change</button> */}
    </div>
  )
}

