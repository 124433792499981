import React, {useState, useEffect} from 'react';
import Service from '../components/Service';

export default function Media(props) {

  const [image, setImage] = useState();
  const [images, setImages] = useState([]);
  const [filter, setFilter] = useState();

  const getImages=()=>{
    Service.get('/media/getImages', {filter}, (res)=>{
      setImages(res.images);
    })
  }

  useEffect(() => {
    getImages();
    }, []);


  const uploadImage = (event) => {
        const files = Array.from(event.target.files)
        // setLoader(true);

        const formData = new FormData()

        files.forEach((file, i) => {
        formData.append(i, file)
        })

        console.log('formData:', formData);

        Service.uploadNewsImages(formData,(res)=>{
            //this.setState({image: res.data});
            //setImage(res.data);
            getImages();
            // setLoader(false);
        });
    }
  
  return (
    <div className={props.className}>
        <div className="head">
        <h3 className="align-center">Media</h3>
        
            <label for="multi" class="btn">Aplouduj sliku</label>
            <input type='file' id='multi' name="userImage[]" onChange={uploadImage} multiple data-buttonText={"Your label here."} />
            <h3>Odaberi sliku:</h3>
        </div>   
        <div className="body">
            {images.map(item => {
                return(
                    <img style={image == item ? {border: '3px solid black'} : {}} src={item.url} onClick={()=>setImage(item)} title="Klikni da odaberes sliku"/>
                )
            })

            }
        </div>
        <div className="media-footer">
          <button  onClick={()=>props.onSelect(image)} >Izaberi</button>
          <button className="button-cancel" onClick={props.onCancel}>Odustani</button>
        </div>
        
    </div>
  )
}

