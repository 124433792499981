import {Auth} from '../components/Auth';
import { BrowserRouter as Router, Route, withRouter, Link, Redirect } from "react-router-dom";


// const hostname = window && window.location && window.location.hostname;
var api = 'https://api.cloudninetravel.rs/admin';
var apiPublic = 'https://api.cloudninetravel.rs/api';
var images = 'https://api.cloudninetravel.rs/media/';

// if(window.location.origin == 'http://localhost:3000' || window.location.origin == 'http://localhost:3006'){
//     api = 'http://api.pp.cekaonica.com/admin';
//     apiPublic = 'http://api.pp.cekaonica.com/api';
//     images = 'http://api.pp.cekaonica.com/media/';
//     secondaryImages = 'http://api.pp.cekaonica.com/secondary-images/';
//     lastOrderBeforeDexpress = 20409;
// }

const Service = {

    images(image){
        return images + image;
    },

    getStoreName(storeId){
        if(parseInt(storeId) == 1){
            return 'Harmonija';
        }
        if(parseInt(storeId) == 2){
            return 'Stela';
        }
        if(parseInt(storeId) == 3){
            return 'Publik';
        }
        return '-';
    },

    price(price){
      if(price){
          let p = '';
          if(Number.isInteger(price)){
              let parsedPrice = new Intl.NumberFormat('de-DE', {currency: 'EUR' }).format(price);
              p = parsedPrice + ",00";
          }else{
              let arr = price.toString().split('.');

              if(arr[1].length < 2){
                  arr[1] += '0';
              }

              let parsedPrice = new Intl.NumberFormat('de-DE', {currency: 'EUR' }).format(arr[0]);
              p = parsedPrice + ',' + arr[1];
          }
          return p + ' RSD';
      }
      return '0,00 RSD';
  },

    // secondaryImages: secondaryImages,

    get(url, params, cb=null){
        params.Auth = Auth;

        // fetch(`${process.env.REACT_APP_API}${url}`,
        fetch(`${api}${url}`,
          {
            method: 'post',
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: JSON.stringify(params)
           })
          .then(res => res.json())
          .then(
            (result) => {
                if(result.authenticated != false){
                    if(cb){
                        if(result.message){
                            alert(result.message);
                        }
                        cb(result);
                    }
                }else{
                    Auth.isAuthenticated = false;
                    //window.location.replace("/login");
                    //history.push("/login");
                }
            },
            (error) => {
                console.log('error', error);
            }
        )
    },

    api: api,

    uploadProductImages(formData){
      fetch(`${api}/media/upload`, {
        method: 'post',
        headers: {'content-type': 'multipart/form-data'},
        data: formData,
        body: JSON.stringify({
            Auth,
            images: formData
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result);
        },
        (error) => {
          this.setState({
            // isLoaded: true,
            // error
          });
        }
      )
    },

    uploadNewsImages(formData, cb){
    fetch(`${api}/media/uploadNews`,
      {
        method: 'post',
        //headers: {'Content-Type':'application/x-www-form-urlencoded'},
        body: formData
       })
      .then(res => res.json())
      .then(
        (result) => {
          //console.log(result);
            cb(result);
            // this.setState({image: result.data});
            //this.getNews();
          
        },
        (error) => {
          console.log('error mus')
        }
      )
    },

    uploadEmailFile(formData, cb){
    fetch(`${this.api}/users/uploadEmail`,
      {
        method: 'post',
        body: formData
       })
      .then(res => res.json())
      .then(
        (result) => {
          //console.log(result);
            cb(result);
            // this.setState({image: result.data});
            //this.getNews();
          
        },
        (error) => {
          this.setState({
            // isLoaded: true,
            // error
          });
        }
      )
    },

    syncErpOrders(orderId, cb){
    fetch(`${apiPublic}/orders/addErpOrder?id=${orderId}`,
      {
        method: 'post',
        body: {}
       })
      .then(res => res.json())
      .then(
        (result) => {
          //console.log(result);
            cb(result);
            // this.setState({image: result.data});
            //this.getNews();
          
        },
        (error) => {
          cb({error: 'not synced'});
        }
      )
    },

}

export default Service;