//import React, { Component } from 'react';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import {Auth} from '../components/Auth';

export default function Login(props){

  const [loginName, setLoginName] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  const {setLoggedIn} = useContext(AuthContext)

  const login = (event) => {

    if(event.key){
      if(event.key == "Enter"){
        Auth.userName = loginName;
        
        Auth.authenticate(loginPassword, () => {
            // update auth context
            setLoggedIn(true);
            // redirect to home page home page in this case
            props.history.push('/');
        })
      }
    }else{
      Auth.userName = loginName;
        
        Auth.authenticate(loginPassword, () => {
            // update auth context
            setLoggedIn(true);
            // redirect to home page home page in this case
            props.history.push('/');
        })
    }
  }

    return (
      <div className="align-center">
          <h1 style={{marginTop: '3em'}}>Cloud 9 Premium Travel</h1>
          <section style={{width: '30%', margin: 'auto'}}>
                <h1>Uloguj se</h1>
                <input autoFocus={true} placeholder="Korisničko ime" value={loginName} onChange={(event)=>setLoginName(event.target.value)} />
                <input type="password" placeholder="Šifra" value={loginPassword} onChange={(event)=>setLoginPassword(event.target.value)} onKeyPress={login}/>
                <br/><button onClick={login} >Uloguj se</button>
            </section>
      </div>
    );

}

