import React, { Fragment, useContext } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect} from "react-router-dom";


import Header from './components/Header';
import UserMenu from './components/UserMenu';

import Login from './views/Login';
import Forbidden from './views/Forbidden';
import Home from './views/Home';
import Hotels from './views/Hotels';
import Offers from './views/Offers';
import AirTickets from './views/AirTickets';
import RentACar from './views/RentACar';

import {Auth} from './components/Auth';
import Options from './views/Options';
import { AuthContext } from './context/AuthContext';


const checkLoginStatus = () => {

     if (localStorage.getItem('token')) {
        let ted = localStorage.getItem('ted');
        let now = new Date()  
        let time = Math.round(now.getTime() / 1000)
        console.log('date', time);
        if(time < ted - 3600){
            console.log('tes');
            Auth.isAuthenticated = true;
            Auth.token = localStorage.getItem('token');
            Auth.id = localStorage.getItem('id');
            Auth.userName = localStorage.getItem('userName');
        }
    
  }else {
      Auth.isAuthenticated = false;
  }

}

{Auth.checkLoginStatus()}


const PrivateRoute = ({ component: Component, ...rest }) => (
  
  <Route {...rest} render={(props) => (
    console.log(props.history.location.pathname),
    Auth.isAuthenticated === true && 
    Auth.checkPermission(props.history.location.pathname) ? <Component {...props} /> : <Redirect to={{
        pathname: '/forbidden',
        state: { from: props.location }
      }} />
      
  )} />


)

function App(props) {

const {loggedIn, setLoggedIn} = useContext(AuthContext);

    return (

      <Router>
        <div>
          {Auth.isAuthenticated == false ? (
            <Redirect to="/login" />
          ) : (setLoggedIn(true))
          }
            {loggedIn &&
              <Fragment>
              <Header />
              <UserMenu />
              </Fragment>
            }
          <Route exact path="/login" component={Login} />
          <div className="content">
            
            <Route exact path="/forbidden" component={Forbidden} />
            <PrivateRoute exact path="/" component={Home} />
            {/* <PrivateRoute path="/:page" component={Home} /> */}
            <PrivateRoute exact path="/offers/:id?" component={Offers} />
            <PrivateRoute exact path="/hotels/:id?" component={Hotels} />
            <PrivateRoute exact path="/air-tickets" component={AirTickets} />
            <PrivateRoute exact path="/rent-a-car" component={RentACar} />
            <PrivateRoute exact path="/options" component={Options} />
          
          </div>
        </div>
      </Router>
    );
}

export default App;
