import React, { useState } from "react";

export const AuthContext = React.createContext();
const logMessage = message => {
  console.log(message);
};
export const AuthProvider = props => {
  const [loggedIn, setLoggedIn] = useState(false);
  const contextValue = {
    loggedIn,
    setLoggedIn,
    logMessage
  };
  return (
    <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
  );
};