import React from 'react';
import { BrowserRouter as Router, Route, withRouter, Link, useLocation } from "react-router-dom";

const menuItems = [
	{path: '/',text: "Početna"},
	{path: '/offers',text: "Ponude"},
	{path: '/hotels',text: "Hoteli"},
	{path: '/air-tickets',text: "Avio Karte"},
	{path: '/rent-a-car',text: "Rent a Car"},
	{path: '/options',text: "Opcije"},
]

export default function Header(props){

const location = useLocation();

	// open close menu
	const handleMenu = () => {
		if(window.innerWidth <= 600){
			let e = document.getElementById('menu');
			if(e.style.display == 'none' || !e.style.display){
				e.style.display = 'block';
			}else{
				e.style.display = 'none';
			}
			console.log(e);
		}
	}

	const currentPage=(path)=>{
		return location.pathname == path ? 'header-active': null;
	}

	return (
		<div id="header">
			<div id="hamburger" onClick={handleMenu}>☰</div>
			<ul id='menu' onClick={handleMenu} >
				{menuItems.map(item => {
					return(
						<li className={currentPage(item.path)}>
							<Link to={item.path}>{item.text}</Link>
						</li>
					)
				})}
				{/* <li className={currentPage('/')}>
					<Link to="/">Početna</Link>
				</li> 
				<li className={currentPage('/hotels')} >
					<Link to="/hotels">Hoteli</Link>
				</li>
				<li className={currentPage('/air-tickets')}>
					<Link to="/air-tickets">Avio-karte</Link>
				</li>
				<li className={currentPage('/rent-a-car')}>
					<Link to="/rent-a-car">Rent-a-car</Link>
				</li>
				<li className={currentPage('/options')}>
					<Link to="/options">Opcije</Link>
				</li> */}
			</ul>       
		</div>
	)
}

//export default withRouter(props => <Header {...props}/>);
