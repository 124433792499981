import React, { Component } from 'react';

class Options extends Component {

    constructor(props) {
    super(props);
    this.state = {
        options: []
    }
  }

  render() {
    return (
      <div>
          <h1>Opcije</h1>
          
      </div>
    );
  }

}

export default Options;
