import React, {useState, useEffect, useContext} from 'react';
import { AuthContext } from '../context/AuthContext';
import Service from '../components/Service';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import Media from '../components/Media';

function showStars(n){
  let r = '';
  for (var i = 0; i < parseInt(n); i ++){
    r += '★';
  }
  return r;
}

function cutText(text, length = 50){
  let res;
  if(text.length > length){
    res = text.substring(0,length);
    let li = res.lastIndexOf(" ");
    return res.substring(0, li) + '...';
  }else{
    return text;
  }
}

const modules = {
  toolbar: [
    [{ 'header': [2, 3, false] }],
    ['bold', 'italic', 'underline','strike'],
    // ['link', 'image'],
    ['link'],
    ['clean'],
    [{'list': 'ordered'}, {'list': 'bullet'}]
  ],
}

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike',
  'link', 'list' //'image',
  
]



export default function Hotels(props) {

  const [hotels, setHotels] = useState([]);
  const [add, setAdd] = useState();

  const [newHotel, setNewHotel] = useState({});

  

  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [stars, setStars] = useState(null);
  const [countriesFilter, setCountriesFilter] = useState(null);
  const [image, setImage] = useState();
  const [media, setMedia] = useState(false);
  const [types, setTypes] = useState([]);
  const [price, setPrice] = useState();
  const [shortDescription, setShortDescription] = useState();
  const [selectedCountry, setSelectedCountry] = useState({label: null, value: null});
  const [selectedType, setSelectedType] = useState({label: null, value: null});

  const [selectedHotel, setSelectedHotel] = useState();

  const {loggedIn, setLoggedIn} = useContext(AuthContext);

  const getHotels=()=>{
    Service.get('/hotels/get', {}, (res)=>{
      setHotels(res.accommodation);
      let t = [];
      for(var p in res.types){
        t.push({label: res.types[p], value: p}) 
      }

      setTypes(t);
    })
  }


  const getCountries = (inputValue, cb) => {
    Service.get('/hotels/getCountries', {filter: countriesFilter}, (res)=>{
      let options = [];
      res.countries.map(item => {
        options.push({label: item.name, value: item.id});
      })
      cb(options);
    })

  };

  const handleInputCountries = (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    setCountriesFilter(inputValue);
    return inputValue;
  };

  useEffect(() => {
    if(props.match.params.id){

    }else{
      getHotels();
    }
    
    }, []);
  
  const addHotel = () => {

    if(!name || !stars || !price){
      alert('Greska! Niste uneli sve podatke.', name, selectedType.value, stars, price);
      return;
    }

    let params = {name: name,
      description: description,
      stars: stars,
      type: selectedType ? selectedType.value : 1,
      image: image ? image.name : null,
      price: price,
      shortDescription: shortDescription,
      countryId: selectedCountry.value
    }
    if(selectedHotel){
      params.id = selectedHotel;
      Service.get('/hotels/update', params, (res)=>{
        if(res.success){
          resetInputs();
          alert('Izmene su uspesno sacuvane.');
          getHotels();
        }
      })
    }else{
      Service.get('/hotels/addHotel', params, (res)=>{
        if(res.success){
          resetInputs();
          alert('Hotel je uspesno dodat');
          getHotels();
        }
      })
    }
  }

  const resetInputs = () => {
      setDescription(null);
      setShortDescription(null);
      setPrice(null);
      setName(null);
      setStars(null);
      setImage(null);
      setAdd(false);
      setSelectedType({label: null, value: null});
      setSelectedHotel(null);
  }

  useEffect(()=>{
    if(selectedHotel){
      getHotel();
    }
    
  }, [selectedHotel])

  const editHotel=(hotelId)=>{
    setSelectedHotel(hotelId);
  }

  const getHotel=()=>{
    Service.get('/hotels/getHotel', {id: selectedHotel}, (res)=>{
      setAdd(true);
      setDescription(res.hotel.description);
      setShortDescription(res.hotel.short_description);
      setPrice(res.hotel.price);
      setName(res.hotel.name);
      setStars(res.hotel.stars);
      setImage({url: res.hotel.image, name: res.hotel.image_name});
      setSelectedCountry({label: res.hotel.country_name, value: res.hotel.country})
      setSelectedType({label: res.hotel.type_text, value: res.hotel.type});
    })
  }

  const deleteHotel=(hotelId, hotelName)=>{
    if(window.confirm("Da li ste sigurni da želite da obrišete smestaj " + hotelName)){
      Service.get('/hotels/delete', {id: hotelId}, (res)=>{
        if(res.success){
          getHotels();
        }
      })
    }
  }

  const changeStatus=(id)=>{
    Service.get('/hotels/changeStatus', {id}, (res)=>{
      if(res.success){
        getHotels();
      }
    })
  }


  
  
  return (
    <div>
      {/* <button onClick={()=>{setNewHotel( prevState => ({...prevState, name: 'vojno', test: 'test'})); console.log(newHotel)}}>test</button> */}
      {/* <button onClick={()=>{setNewHotel( prevState => ({...prevState, desc: 'kiki'})); console.log(newHotel)}}>test</button> */}
      <h1>Smestaj {props.match.params.id}</h1>
      {add ? (
      <section>
        {selectedHotel ?
          <h2 className="align-center">Izmena podataka o smeštaju</h2>
          :
          <h2 className="align-center">Novi smestaj</h2>
          
        }
        <div className="flex">
          <div className="half">
            {/* <label>Ime</label> */}
            <input placeholder="Ime" onChange={(event)=>setName(event.target.value)} value={name} />
            <div className="flex">
            <div className="half">
              {/* <label>Zvezdice</label> */}
              <input placeholder="Zvezdice" type="number" onChange={(event)=>setStars(event.target.value)} value={stars} />
            </div>
            <div className="half">
              
              {/* <label>Cena</label> */}
              <input placeholder="Cena" type="number" onChange={(event)=>setPrice(event.target.value)} value={price} />
            </div>
          </div>
        
        {/* <label>Tip smeštaja</label> */}
        {types &&
          <Select
          value={selectedType.label ? selectedType : {label: 'Odaberi tip...', value: 0}}
            placeholder="Tip smeštaja..."
            isSearchable={false}
            name="type"
            options={types}
            className="react-selector"
            onChange={(item) => setSelectedType(item)}
          />
        }

        {/* <label>Država</label> */}

          <AsyncSelect
            value={selectedCountry.label ? selectedCountry : {label: 'Odaberi zemlju...', value: 0}}
            cacheOptions
            loadOptions={getCountries}
            defaultOptions
            onInputChange={handleInputCountries}
            className="react-selector"
            placeholder="Odaberi državu..."
            onChange={(item) => {setSelectedCountry(item)}}
          />
        
        <label>Slika</label>
        {image &&
          <img src={image.url} style={{height: '5em', display: 'block'}}/>
        }
        <button onClick={()=>setMedia(true)}>{image ? 'Promeni sliku' : 'Dodaj sliku'}</button>
        {/* <div>
            <label for="multi" class="btn">Odaberi sliku:</label>
            <input type='file' id='multi' name="userImage[]" onChange={uploadImage} multiple data-buttonText={"Your label here."} />
          </div> */}
        </div>
        <div className="half">
          {/* <label>Kratki opis</label> */}
              <input placeholder="Kratki opis"  onChange={(event)=>setShortDescription(event.target.value)} value={shortDescription} />
          {/* <label>opis</label> */}
          <ReactQuill value={description}
            placeholder="Opis hotela..."
            className="text-editor"
                      modules={modules}
                      formats={formats}
                    onChange={(value)=>setDescription(value)} 
          />
          <div className="align-right">
            <button onClick={addHotel}>Sačuvaj</button>
            <button className="button-cancel" onClick={()=>setAdd(false)}>Odustani</button>
          </div>
        </div>
        {media &&
          <Media className="media-module" onCancel={()=>{setMedia(false)}} onSelect={(image)=>{setImage(image); setMedia(false)}} />
        }
        </div>
      </section>
      ):(
        <button onClick={()=>setAdd(true)}>Dodaj smestaj</button>
      )
      }
      <div>
        <table className="cms-table">
          <thead>
              <tr>
                <th>id</th>
                <th>Slika</th>
                <th>Ime</th>
                <th>Opis</th>
                <th>Tip</th>
                <th>Zvezdice</th>
                <th>Zemlja</th>
                <th>Cena</th>
                <th>Aktivan</th>
                <th></th>
              </tr>
          </thead>
          <tbody>
          {hotels.map(item => {
            return(
              <tr>
                <td>{item.id}</td>
                <td onClick={()=>editHotel(item.id)}><img style={{cursor: 'pointer'}} src={item.image} style={{width: '64px'}}/></td>
                <td><a onClick={()=>editHotel(item.id)} href="#">{item.name}</a></td>
                {/* <td>{cutText(item.description.replace(/(<([^>]+)>)/gi, " "), 50)}</td> */}
                <td>{item.short_description}</td>
                <td>{item.type_text}</td>
                <td>{showStars(item.stars)}</td>
                <td>{item.country_name}</td>
                <td>{item.price}</td>
                <td><input type="checkbox" checked={item.status} onChange={()=>changeStatus(item.id)}/></td>
                <td><button className="button-red" onClick={()=>deleteHotel(item.id, item.name)}>Obriši</button></td>
              </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

