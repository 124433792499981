import React, { Component } from 'react';

class AirTickets extends Component {

    constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
          <h1>Air tickets</h1>
          
      </div>
    );
  }

}

export default AirTickets;
