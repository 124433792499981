import Service from '../components/Service';
import {LS} from '../components/LS';
import React, { useContext } from 'react';
import { AuthContext } from '../context/AuthContext';

//order_administrator, products_administrator
const permissions = {
    ['/news']: ['order_administrator'],
    ['/statistics']: ['order_administrator', 'products_administrator'],
    ['/chat']: ['products_administrator'],
    ['/orders']: ['order_administrator'],
    ['/products']: ['products_administrator']
}

export function Test(){
    const {loggedIn} = useContext(AuthContext);
    console.log('aa', loggedIn);
    return loggedIn;
}

export const Auth = {

    isAuthenticated: false,
    userName: false,
    token: false,
    role: null,

    

    authenticate(password, cb) {
        Service.get('/users/login',{userName: this.userName, password: password},(res)=>{
            if(res.success){

        		this.isAuthenticated = true;
                this.token = res.data.token;
                this.id = res.data.id;
                this.userName = res.data.userName;
                this.ted = res.data.ted;
                this.role = res.data.role;

                LS.set({Auth: {
                    token: res.data.token,
                    id: res.data.id,
                    userName: res.data.userName,
                    ted: res.data.ted,
                    role: res.data.role
                }})
                
        		console.log(res);
        		setTimeout(cb, 100);
        		
        	}else{
        		this.isAuthenticated = false;
        	}
        }) 	
    },

    checkLoginStatus(){

        this.isAuthenticated = false;

        if(LS.data.Auth){
            let ted = LS.data.Auth.ted;
            let now = new Date(); 
            let time = Math.round(now.getTime() / 1000);
            if(time < ted - 3600){

                this.isAuthenticated = true;
                this.token = LS.data.Auth.token;
                this.ted = LS.data.Auth.ted;
                this.id = LS.data.Auth.id;
                this.userName = LS.data.Auth.userName;
            }
        }
   },

    logout(cb) {
        Service.get('/users/logout',{userName: this.userName, token: this.token},()=>{
            this.signout(cb);
            });
    },

  
    signout(cb) {
        this.logout();
        this.isAuthenticated = false

        LS.set({Auth: null});
        setTimeout(cb, 100)
    },

    

    

    checkPermission(route){
       // console.log('route', route, this.role, Auth);
        if(this.role == 'superadmin') return true;
        if(permissions[route]){
            if(permissions[route].includes(this.role)){
            return true;
            }
            return false;
        }else{
            return true;
        }
    }
}