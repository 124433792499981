import React, {useContext} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './style-mobile.css';
import './selector.css';
import App from './App';
import './fonts/HelveticaNeueLTPro-Roman.otf';
import { AuthProvider } from './context/AuthContext';
//import * as serviceWorker from './serviceWorker';

ReactDOM.render(
<AuthProvider>
<App /></AuthProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
